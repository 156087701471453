import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import { HomeFeature } from "../components/Home"
import SEO from "../components/SEO/seo"
import TestimonialIndex from "../components/testimonial"
import {
  CardContainer,
  CommonCard,
  CommonTitle,
  StyledA,
  GroupManageTitle,
  BottomLine,
} from "../components/commonFeatureComp"
import BgImgComp from "../components/bgImgComp"
import { GatsbyImage } from "gatsby-plugin-image"
import bgWeb from "../../images/bg-1.svg"
import bgMobile from "../../images/bg-2.svg"

const H1ImageContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  position: relative;
  align-content: center;
`
const H1Container = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
`

const TitleGrid = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  margin-top: 10px;
`
const Title = styled.h2`
  font-size: 32px;
  color: #2f394e;
  text-align: center;
  padding: 0 15px;
  @media (max-width: 575px) {
    font-size: 18px;
  }
`
const TitleH1 = styled.h1`
  box-sizing: border-box;
  color: #fff;
  font-size: ${props => (props.fontSize ? props.fontSize : `42px`)};
  margin: 0;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
  padding: 20px;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 575px) {
    font-size: 23px;
  }
`
const TitleH3 = styled.h3`
  font-size: 24px;
  color: #33475b;
  text-align: start;
  margin: 2rem 0 0.5rem 0;
  @media (max-width: 1280px) {
    font-size: 22px;
  }
`
const Bottomline = styled.div`
  width: 50px;
  height: 3px;
  border-radius: 10px;
  background-color: #33cdd1;
  margin: 0 auto;
`
const TitleTextContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(300px, 1000px);
  justify-content: center;
  padding: 15px 40px;
  @media (max-width: 575px) {
    padding: 15px 20px;
  }
`
const GroupManageTitleContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(1000px, 1250px);
  padding: ${props => props.padding || `3rem 30px`};
  justify-content: center;
  @media (max-width: 1024px) {
    grid-template-columns: 92%;
  }
  @media (max-width: 800px) {
    grid-template-columns: auto;
    padding: 0 20px;
    padding-bottom: ${props => props.isRefund && `25px`};
    text-align: center;
  }
`
const TitleText = styled.p`
  font-size: ${props => props.fontSize || `18px`};
  text-align: ${props => props.textAlign || `center`};
  margin: ${props => props.margin || `1.7rem 0 1rem 0`};
  line-height: 1.6;
  color: #3d4d69;
  @media (max-width: 575px) {
    font-size: 14px;
  }
`
const FeaturesCompareContainer = styled.div`
  max-width: 1250px;
  display: grid;
  grid-template-columns: 1.7fr 1fr 1fr;
  padding: 30px;
  /* justify-items: center; */
  margin: 0 auto;
  background-color: #fff;
  :nth-of-type(even) {
    background-color: #f8fcfc;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 575px) {
    padding: ${props => (props.title ? `25px 0` : `25px 10px`)};
    margin: 0 20px;
    grid-template-columns: 1.5fr 1fr 1fr;
  }
`
const FeatureCompareElement = styled.div`
  margin: 0;
  color: #33475b;
  font-size: ${props => (props.title ? `22px` : `16px`)};
  padding: 0 15px;
  display: grid;
  align-content: center;
  justify-content: ${props => props.icon && `center`};
  font-weight: ${props => props.title && `700`};
  @media (max-width: 575px) {
    padding: ${props => (props.title ? `0` : `0 5px`)};
    font-size: ${props => (props.title ? `14px` : `13px`)};
  }
`

const BrandComponent = styled.div`
  display: grid;
  grid-row-gap: 15px;
  padding: 0 20px;
  @media (max-width: 575px) {
    padding: 0 5px;
  }
`
const FreeTrialCapsule = styled.div`
  color: white;
  font-size: 14px;
  background: ${props => (props.freeTrial ? `#4DBB9D` : `#F95663`)};
  border-radius: 17px;
  padding: 8px 20px;
  margin: 0 auto;
  display: grid;
  place-items: center;
  text-align: center;
  @media (max-width: 575px) {
    display: none;
  }
`

const FreeTrialButton = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  background-color: #fff;
  padding: 20px 35px;
  border-radius: 6px;
  border: 1px solid #33cbcf;
  font-size: 18px;
  margin: 5vh auto;
  &:hover {
    color: #fff;
    background-color: #33cbcf;
  }
  @media (max-width: 1280px) {
    font-size: 16px;
  }
`
const PlanCardContainer = styled.div`
  display: grid;
  grid-template-columns: 520px 520px;
  grid-column-gap: 40px;
  justify-content: center;
  margin-bottom: 40px;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
    padding: 0 20px;
  }
  @media (max-width: 768px) {
    grid-template-columns: 400px;
    grid-row-gap: 20px;
  }
  @media (max-width: 450px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`
const PlanCard = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  border: 1px solid #c2cce1;
  border-radius: 30px;
  grid-row-gap: 35px;
  padding: 35px 0;
  background-color: #fff;
`
const PlanCardTableGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 5px 25px;
  padding: 0 35px;
`
const PlanCardText = styled.p`
  margin: 0;
  font-size: ${props => (props.title ? `36px` : `16px`)};
  font-weight: ${props => props.title && `700`};
  padding: ${props => props.title && `0 35px`};
`
const TablePaddedContainer = styled.div`
  background-color: #eafafa;
  padding-top: 30px;
`
const ColoredContainer = styled.div`
  display: grid;
  padding: ${props => (props.isProsCons ? `45px 50px` : `30px 40px`)};
  margin: 30px 0;
  border-radius: 10px;
  background-color: ${props => props.bgColor || `#eafafa`};
  @media (max-width: 575px) {
    padding: ${props => (props.isProsCons ? `25px` : `10px 25px`)};
  }
`
const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  padding: 30px 0 1.8rem;
  @media (max-width: 575px) {
    grid-template-columns: 1fr;
  }
`
const WebView = styled.div`
  @media (max-width: 575px) {
    display: none;
  }
`
const MobileView = styled.div`
  display: none;
  @media (max-width: 575px) {
    display: block;
  }
`

const FeatureCompareRow = props => (
  <FeaturesCompareContainer>
    <FeatureCompareElement>{props.featureText}</FeatureCompareElement>
    <FeatureCompareElement icon>
      {props.element.groupboss}
    </FeatureCompareElement>
    <FeatureCompareElement icon>{props.element.groupX}</FeatureCompareElement>
  </FeaturesCompareContainer>
)

const ColorButton = styled.div`
  padding: 16px 30px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #33cdd1;
  border: 1px solid #33cdd1;
  white-space: nowrap;
  border-radius: 6px;
  font-size: 18px;
  &:focus {
    outline: none;
  }
  &:hover {
    color: #33cdd1;
    background-color: #fff;
  }
`
const ResponsiveImageWeb = styled.img`
  @media (max-width: 575px) {
    display: none;
  }
`
const ResponsiveImageMobile = styled.img`
  display: none;
  @media (max-width: 575px) {
    display: block;
  }
`
const CustomList = styled.div`
  display: grid;
  grid-template-columns: 16px 1fr;
  grid-gap: 25px 15px;
  padding: 25px 0;
  align-items: center;
`
const ColoredDot = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${props => props.bgColor || `#92A2FA`};
`
const ListText = styled.p`
  font-size: 18px;
  font-weight: 400;
  text-align: start;
  margin: 0;
  color: #33475b;
`

export default ({ data, location }) => (
  <>
    <SEO
      title="Group Collector Alternative & Review in 2024"
      description="This article discussed why people look for Group collector alternatives. Find Groupboss – the best Group Collector alternative in 2024."
      pathname={location.pathname}
    />

    <H1ImageContainer>
      <ResponsiveImageWeb src={bgWeb} alt="home-background" width="100%" />
      <ResponsiveImageMobile
        src={bgMobile}
        alt="home-background mobile"
        width="100%"
      />
      <H1Container>
        <TitleH1>The Best Group Collector Alternative & Review in 2024</TitleH1>
      </H1Container>
    </H1ImageContainer>

    <TitleTextContainer>
      <TitleText>
        Group Collector is trying to perform quite better in automating Facebook group lead generation. However, it seems users are not that satisfied with tool. There
        are many alternatives to this tool that you can explore. Definitely, you
        will find better tools than Group Collector in the market.
      </TitleText>

      <TitleText>
        To know the pros and cons of Group Collector, check the last part of
        this article. There is a short review of this tool.
      </TitleText>
      <TitleText>
        If you are looking for the best Group Collector alternative, you can
        check out Groupboss.
      </TitleText>
    </TitleTextContainer>

    <div
      style={{ display: `grid`, justifyContent: `center`, margin: `20px 0` }}
    >
      <Link
        to="/pricing"
        target="_blank"
        style={{ textDecoration: "none", color: "#33475B", margin: `0 auto` }}
      >
        <ColorButton>Sign Up at Groupboss</ColorButton>
      </Link>
    </div>

    <TitleTextContainer>
      <CommonTitle
        fontSize="32px"
        fontWeight="700"
        margin="4.5rem 0"
        color="#33475B"
        style={{ textAlign: `center` }}
      >
        Let’s have a quick look at the comparison of features between Groupboss
        and Group Collector.
      </CommonTitle>
    </TitleTextContainer>

    <TablePaddedContainer>
      <FeaturesCompareContainer title style={{ background: `#eafafa` }}>
        <FeatureCompareElement title>
          Features and Service{" "}
        </FeatureCompareElement>
        <BrandComponent>
          <WebView>
            <GatsbyImage
              image={data.gb_logo.childImageSharp.gatsbyImageData}
              alt="groupboss"
            />
          </WebView>
          <MobileView>
            <GatsbyImage
              image={data.gb_logo_mobile.childImageSharp.gatsbyImageData}
              alt="groupboss logo mobile"
              style={{ width: `50px`, margin: `0 auto` }}
            />
          </MobileView>
        </BrandComponent>
        <BrandComponent>
          <WebView>
            <GatsbyImage
              image={data.grouptrack_crm_logo.childImageSharp.gatsbyImageData}
              alt="groupleads logo"
            />
          </WebView>
          <MobileView>
            <GatsbyImage
              image={data.gl_logo_mobile.childImageSharp.gatsbyImageData}
              alt="groupleads logo mobile"
              style={{ width: `50px`, margin: `0 auto` }}
            />
          </MobileView>
        </BrandComponent>
      </FeaturesCompareContainer>

      <FeatureCompareRow
        featureText="Onboarding and set up"
        element={{
          groupboss: "Very easy",
          groupX: "A bit complex",
        }}
      />
      <FeatureCompareRow
        featureText="Google sheets Integration"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
        }}
      />
      <FeatureCompareRow
        featureText="Dashboard"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
        }}
      />

      <FeatureCompareRow
        featureText="Integration with Autoresponders"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
        }}
      />
      <FeatureCompareRow
        featureText="Zapier Required"
        element={{
          groupboss: "Optional",
          groupX: "Optional",
        }}
      />
      <FeatureCompareRow
        featureText="Custom & Lookalike Audience"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
        }}
      />

      <FeatureCompareRow
        featureText="Export all data from dashboard in CSV or Excelformat"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.cross.childImageSharp.gatsbyImageData}
              alt="cross"
            />
          ),
        }}
      />

      <FeatureCompareRow
        featureText="Chat Support"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.cross.childImageSharp.gatsbyImageData}
              alt="cross"
            />
          ),
        }}
      />
      <FeatureCompareRow
        featureText="Membership Autoapproval"
        element={{
          
          groupboss: (
            <GatsbyImage
              
              image={data.cross.childImageSharp.gatsbyImageData}
              alt="cross"
            />
            
          ),
          groupX: (
            <GatsbyImage
            image={data.tick.childImageSharp.gatsbyImageData}
            alt="tick"
            />
          ),
        }}
      />
      <FeatureCompareRow
        featureText="Support in Email"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
        }}
      />
      <FeatureCompareRow
        featureText="Starting Price"
        element={{
          groupboss: "$45/Quarter",
          groupX: "$25/Month",
        }}
      />
      <FeatureCompareRow
        featureText="Refund Policy"
        element={{ groupboss: "14 Days", groupX: "14 Days" }}
      />
      <FeatureCompareRow
        featureText="Separate Email column"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.cross.childImageSharp.gatsbyImageData}
              alt="cross"
            />
          ),
        }}
      />

      <FeatureCompareRow
        featureText="Exclusive support through zoom/skype/Gmeet"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.cross.childImageSharp.gatsbyImageData}
              alt="cross"
            />
          ),
        }}
      />
      <FeatureCompareRow
        featureText="Free trial"
        element={{
          groupboss: "Available",
          groupX: "Available",
        }}
      />
      <FeatureCompareRow
        featureText="Free Set Up Call"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: "Maybe",
        }}
      />
      <FeatureCompareRow
        featureText="Tutorial for all features"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: "No",
        }}
      />
      <div style={{ display: `grid`, justifyContent: `center` }}>
        <Link
          to="/pricing"
          target="_blank"
          style={{ textDecoration: "none", color: "#33475B" }}
        >
          <FreeTrialButton>Get 7 Days Free Trial at Groupboss</FreeTrialButton>
        </Link>
      </div>
    </TablePaddedContainer>

    <GroupManageTitleContainer>
      <GroupManageTitle>What is Group Collector?</GroupManageTitle>
      <BottomLine />

      <TitleText textAlign="start">
        Group Collector is a social media marketing tool that lets you collect
        information from new Facebook group members and turn them into leads in
        your email marketing program.
      </TitleText>

      <GroupManageTitle>
        Why do people look for Group Collector alternatives?
      </GroupManageTitle>
      <BottomLine />

      <TitleText textAlign="start">
        There are multiple reasons why people look for Group Collector
        alternatives. Some of them are:
      </TitleText>

      <TitleH3>Poor Customer Support</TitleH3>
      <TitleText textAlign="start">
        Customer satisfaction should be the main concern of any business. But,
        it seems Group Collector does not provide support properly. They have a
        chat button on their website that will ultimately lead you to email
        support. So, you can not tell that an instant chat support.
      </TitleText>

      <TitleH3>Lack of onboarding guides</TitleH3>
      <TitleText textAlign="start">
        Group collector lacks tutorials and documentation of the different
        features they have. Everyone loves to have well-organized tutorials to
        learn about software or tool.
      </TitleText>

      <TitleH3>Group Collector is Expensive </TitleH3>
      <TitleText textAlign="start">
        When compared to its competitors, their pricing is higher. Everyone is
        concerned about money. Why would anyone spend so much money on software
        when there are equivalent solutions available in the market at a lower
        cost?
      </TitleText>

      <GroupManageTitle>Let’s look at Group Collector pricing</GroupManageTitle>
      <BottomLine />
      <ColoredContainer>
        <TitleText textAlign="start">
          1. Basic plan - <b>$25/Month</b> that costs <b>$300/Year</b>
        </TitleText>
        <TitleText textAlign="start">
          2. Lifetime Plan -<b>$297</b>
        </TitleText>
        <TitleText textAlign="start">
          Note - <b>Group Collector do not have any affordable yearly plan. </b>
        </TitleText>
      </ColoredContainer>

      <GroupManageTitle>
        Why Should You Choose Groupboss over Group Collector?
      </GroupManageTitle>
      <BottomLine />
      <TitleText textAlign="start">
        With a single click, Groupboss allows you to capture leads and record
        answers to Facebook group questions in Google sheets, dashboard, and
        email autoresponders.
      </TitleText>

      <TitleText textAlign="start">
        With a single click, Groupboss allows you to capture leads and record
        answers to{" "}
        <StyledA
          color="#007AFF"
          textDecoration="underline"
          target="_blank"
          rel="dofollow"
          href="https://groupboss.io/blog/facebook-group-membership-questions/"
        >
          Facebook group qualifying questions
        </StyledA>{" "}
        in Google sheets, dashboard, and email autoresponders.
      </TitleText>
      <TitleText textAlign="start">
        Groupboss is more advanced than Group Collector with comparatively
        cost-effective pricing. Some of the reasons are enlisted below that will
        help you to understand why Groupboss is the best alternative to Group
        Collector.
      </TitleText>
    </GroupManageTitleContainer>

    <div style={{ backgroundColor: `#FDFAF3`, paddingBottom: `2rem` }}>
      <GroupManageTitleContainer>
        <TitleH3>1. Groupboss Pricing</TitleH3>

        <TitleText textAlign="start">
          Group Collector has no yearly plan. You will have to pay $25 every
          month or you will have to purchase the lifetime plan. It seems $25 is
          costlier than the lifetime plan in terms of value as Facebook group
          owners do not create a group for a month only.{" "}
        </TitleText>

        <TitleText textAlign="start">
          Let’s see the difference between the pricing plans to give you a clear
          understanding.
        </TitleText>
      </GroupManageTitleContainer>

      <PlanCardContainer>
        <PlanCard>
          <div style={{ maxWidth: `210px`, paddingLeft: `35px` }}>
            <GatsbyImage
              image={data.gb_logo.childImageSharp.gatsbyImageData}
              alt="groupboss"
            />
          </div>

          <PlanCardText title>
            $45
            <span style={{ fontSize: `22px`, fontWeight: `400` }}>
              {" "}
              / Quarterly
            </span>
          </PlanCardText>
          <PlanCardTableGrid>
            <PlanCardText>Type of Plan:</PlanCardText>
            <PlanCardText>Groupboss Monthly</PlanCardText>
            <PlanCardText>No of Groups:</PlanCardText>
            <PlanCardText>3</PlanCardText>
          </PlanCardTableGrid>
          {/* new added end */}
          <div style={{ borderTop: `1px solid #E8ECF3`, paddingTop: `35px` }}>
            <PlanCardText title>
              $99
              <span style={{ fontSize: `22px`, fontWeight: `400` }}>
                {" "}
                / year
              </span>
            </PlanCardText>
          </div>
          <PlanCardTableGrid>
            <PlanCardText>Type of Plan:</PlanCardText>
            <PlanCardText>Groupboss Yearly</PlanCardText>
            <PlanCardText>No of Groups:</PlanCardText>
            <PlanCardText>3</PlanCardText>
          </PlanCardTableGrid>

          <div style={{ borderTop: `1px solid #E8ECF3`, paddingTop: `35px` }}>
            <PlanCardText title>
              $189
              <span style={{ fontSize: `22px`, fontWeight: `400` }}>
                {" "}
                / year
              </span>
            </PlanCardText>
          </div>
          <PlanCardTableGrid>
            <PlanCardText>Type of Plan:</PlanCardText>
            <PlanCardText>Groupboss Yearly (Mega Plan)</PlanCardText>
            <PlanCardText>No of Groups:</PlanCardText>
            <PlanCardText>20</PlanCardText>
          </PlanCardTableGrid>
        </PlanCard>

        <PlanCard>
          <div style={{ maxWidth: `210px`, paddingLeft: `35px` }}>
            <GatsbyImage
              image={data.grouptrack_crm_logo.childImageSharp.gatsbyImageData}
              alt="group track crm logo"
            />
          </div>
          <PlanCardText title>
            $25
            <span style={{ fontSize: `22px`, fontWeight: `400` }}>
              {" "}
              / month
            </span>
          </PlanCardText>

          <PlanCardTableGrid>
            <PlanCardText>Type of Plan:</PlanCardText>
            <PlanCardText>Monthly</PlanCardText>
          </PlanCardTableGrid>

          <div style={{ borderTop: `1px solid #E8ECF3`, paddingTop: `35px` }}>
            <PlanCardText title> No </PlanCardText>
          </div>
          <PlanCardTableGrid>
            <PlanCardText>Type of Plan:</PlanCardText>
            <PlanCardText>Yearly</PlanCardText>
          </PlanCardTableGrid>

          <div style={{ borderTop: `1px solid #E8ECF3`, paddingTop: `35px` }}>
            <PlanCardText title>$297</PlanCardText>
          </div>
          <PlanCardTableGrid>
            <PlanCardText>Type of Plan:</PlanCardText>
            <PlanCardText>Lifetime</PlanCardText>
          </PlanCardTableGrid>
        </PlanCard>
      </PlanCardContainer>
    </div>

    <GroupManageTitleContainer style={{ backgroundColor: `#EAFAFA` }}>
      <TitleH3>2. Better Customer Experience</TitleH3>
      <TitleText textAlign="start">
        Groupboss customers are extremely satisfied with the service they have
        received. Customer feedback shows that Groupboss values customer
        satisfaction above all else, which is why it provides top-notch support
        for users to ensure a smooth experience no matter how complex your query
        or request maybe!
      </TitleText>
      <ImageContainer>
        <WebView>
          <GatsbyImage
            image={data.better_ux.childImageSharp.gatsbyImageData}
            alt="feedback better user experience"
          />
        </WebView>
        <MobileView>
          <GatsbyImage
            image={data.better_ux_mobile.childImageSharp.gatsbyImageData}
            alt="feedback better user experience"
          />
        </MobileView>
        <TitleText fontSize="16px">
          Source:{" "}
          <span>
            {" "}
            <StyledA
              color="#007AFF"
              textDecoration="underline"
              target="_blank"
              rel="noopener noreferrer"
              href="https://chrome.google.com/webstore/detail/groupboss/gakcpcoikgklfbajjkdaomcfkpeiobfl?hl=en-US"
            >
              Groupboss Chrome Extension
            </StyledA>
          </span>{" "}
        </TitleText>
      </ImageContainer>
    </GroupManageTitleContainer>

    <GroupManageTitleContainer style={{ backgroundColor: `#FFF5F7` }}>
      <TitleH3>3. Detailed Tutorials</TitleH3>
      <TitleText textAlign="start">
        Groupboss has tutorials on the onboarding process, integrations
        tutorials, product updates and more both in written format. You will get
        all of this documentation on their{" "}
        <StyledA
          color="#007AFF"
          textDecoration="underline"
          target="_blank"
          href="https://groupboss.io/help/"
        >
          support page
        </StyledA>{" "}
        as well as videos posted to{" "}
        <StyledA
          color="#007AFF"
          textDecoration="underline"
          target="_blank"
          href="https://www.youtube.com/c/Groupboss"
        >
          YouTube
        </StyledA>{" "}
        for your convenience!
      </TitleText>
      <TitleText textAlign="start">
        These resources are great if you want an instructional guide about how
        use Groupboss.
      </TitleText>

      <TitleText textAlign="start">
        The tutorials are in written or video format, with additional support
        available on their website and YouTube channel!
      </TitleText>

      <TitleText textAlign="start">
        On the other hand, you will not find both types of guides on Group
        Collector.
      </TitleText>
      <ImageContainer>
        <WebView>
          <GatsbyImage
            image={data.detailed_tutorials.childImageSharp.gatsbyImageData}
            alt="feedback detail tutorials"
          />
        </WebView>
        <MobileView>
          <GatsbyImage
            image={
              data.detailed_tutorials_mobile.childImageSharp.gatsbyImageData
            }
            alt="feedback detail tutorials"
          />
        </MobileView>
        <TitleText fontSize="16px">
          Source:{" "}
          <span>
            {" "}
            <StyledA
              color="#007AFF"
              textDecoration="underline"
              target="_blank"
              rel="noopener noreferrer"
              href="https://chrome.google.com/webstore/detail/groupboss/gakcpcoikgklfbajjkdaomcfkpeiobfl?hl=en-US"
            >
              Groupboss Chrome Extension
            </StyledA>
          </span>{" "}
        </TitleText>

        <TitleText textAlign="start">
          <i>
            <b>
              Note: In the Groupboss YouTube channel, you will get not only the
              resources of Groupboss but also different useful tutorials on
              Facebook group features.
            </b>
          </i>
        </TitleText>
      </ImageContainer>
    </GroupManageTitleContainer>

    <GroupManageTitleContainer style={{ backgroundColor: `#F4F8FF` }}>
      <TitleH3>4.Clean and Clear UI/UX</TitleH3>
      <TitleText textAlign="start">
        Customer satisfaction is a great concern for the Groupboss team and they
        are very positive in helping their users for a better user experience.
        Groupboss is easy to use and you can learn about its features within a
        very short time.
        <ImageContainer>
          <GatsbyImage
            image={data.groupboss_poster.childImageSharp.gatsbyImageData}
            alt="groupboss poster"
          />
        </ImageContainer>
        <TitleText textAlign="start">
          Here is the feedback from a Groupboss user!
        </TitleText>
        <ImageContainer>
          <WebView>
            <GatsbyImage
              image={data.clear_ux.childImageSharp.gatsbyImageData}
              alt="feedback clean clear UX"
            />
          </WebView>
          <MobileView>
            <GatsbyImage
              image={data.clear_ux_mobile.childImageSharp.gatsbyImageData}
              alt="feedback clean clear UX"
            />
          </MobileView>
          <TitleText fontSize="16px">
            Source:{" "}
            <span>
              {" "}
              <StyledA
                color="#007AFF"
                textDecoration="underline"
                target="_blank"
                rel="noopener noreferrer"
                href="https://chrome.google.com/webstore/detail/groupboss/gakcpcoikgklfbajjkdaomcfkpeiobfl?hl=en-US"
              >
                Groupboss Chrome Extension
              </StyledA>
            </span>{" "}
          </TitleText>
        </ImageContainer>
      </TitleText>
    </GroupManageTitleContainer>

    <GroupManageTitleContainer>
      <GroupManageTitle>Group Collector Review Summary</GroupManageTitle>
      <BottomLine />
      <TitleText textAlign="start">
        You've learned a lot about Group Collector so far, including its
        features and cost. You'll have to devote a significant amount of effort
        to learning about this technology and making it work for you.
      </TitleText>
      <TitleText textAlign="start">
        This section of the article will provide a summary of this Facebook
        group automation tool, as well as its benefits and drawbacks.
      </TitleText>

      <ColoredContainer isProsCons bgColor="#F8FCFC">
        <TitleH3 style={{ margin: `0 0 1rem` }}>Pros</TitleH3>
        <CustomList>
          <ColoredDot bgColor="#5BEAC3" />
          <ListText>More number of features</ListText>
          <ColoredDot />
          <ListText>
            Direct integrations with email marketing autoresponders
          </ListText>
          <ColoredDot bgColor="#33CBCF" />
          <ListText>Google sheet integration</ListText>
        </CustomList>

        <TitleH3 style={{ margin: `1.5rem 0 1rem` }}>Cons</TitleH3>
        <CustomList>
          <ColoredDot bgColor="#5BEAC3" />
          <ListText>No yearly plan is available.</ListText>
          <ColoredDot />
          <ListText>
            Very few support documents and tutorials to get started.{" "}
          </ListText>
          <ColoredDot bgColor="#33CBCF" />
          <ListText>
          No chat support for the users.

          </ListText>
          <ColoredDot bgColor="#FD99B4" />
          <ListText>
Less number of documentation</ListText>
        </CustomList>
      </ColoredContainer>

      <GroupManageTitle>Verdict</GroupManageTitle>
      <BottomLine />
      <TitleText textAlign="start">
        The difference between Groupboss and Group Collector might seem minimal
        at first glance, but there's actually much more going on here than meets
        the eye; each has its own set of pros & cons depending on which type
        would work better with your business goals as well as budget
        constraints.
      </TitleText>

      <TitleText textAlign="start">
        <b>
          Why should you pay GroupTrack CRM too much once you have got the
          better alternative at a lower price?
        </b>
      </TitleText>

      <TitleText textAlign="start">
        It’s your turn to decide which one to use.
      </TitleText>

      <TitleText textAlign="start">
        Would you love to try the best Group Collector alternative? Just click
        on the below sign up button and explore further.
      </TitleText>
    </GroupManageTitleContainer>

    <div
      style={{ display: `grid`, justifyContent: `center`, margin: `20px 0` }}
    >
      <Link
        to="/pricing"
        target="_blank"
        style={{ textDecoration: "none", color: "#33475B", margin: `0 auto` }}
      >
        <ColorButton>Sign Up at Groupboss</ColorButton>
      </Link>
    </div>

    <GroupManageTitleContainer>
      <TitleText textAlign="start">
        If you are looking into other similar software and their alternatives,
        you may look at the following articles.
      </TitleText>

      <ColoredContainer isProsCons bgColor="#F8FCFC">
        <CustomList>
          <ColoredDot bgColor="#33CBCF" />

          <ListText>
            <StyledA
              color="#007AFF"
              textDecoration="underline"
              target="_blank"
              href="https://groupboss.io/grouptrackcrm-alternative-and-review/"
            >
              The Best GroupTrack CRM Alternative and Review
            </StyledA>{" "}
          </ListText>
          <ColoredDot />

          <ListText>
            <StyledA
              color="#007AFF"
              textDecoration="underline"
              target="_blank"
              href="https://groupboss.io/groupfunnels-alternative/"
            >
              The Best GroupFunnels Alternative and Review
            </StyledA>{" "}
          </ListText>

          <ColoredDot bgColor="#33CBCF" />
          <ListText>
            <StyledA
              color="#007AFF"
              textDecoration="underline"
              target="_blank"
              href="https://groupboss.io/groupconvert-alternative/"
            >
              The Best Groupconvert Alternatives and Review
            </StyledA>{" "}
          </ListText>

          <ColoredDot bgColor="#33CBCF" />
          <ListText>
            <StyledA
              color="#007AFF"
              textDecoration="underline"
              target="_blank"
              href="https://groupboss.io/groupx-alternative/"
            >
              The Best groupX Alternative and Review
            </StyledA>{" "}
          </ListText>

          <ColoredDot bgColor="#33CBCF" />
          <ListText>
            <StyledA
              color="#007AFF"
              textDecoration="underline"
              target="_blank"
              href="https://groupboss.io/groupkit-alternative/"
            >
              The Best GroupKit Alternative and Review
            </StyledA>{" "}
          </ListText>

         

          <ColoredDot bgColor="#33CBCF" />
          <ListText>
            <StyledA
              color="#007AFF"
              textDecoration="underline"
              target="_blank"
              href="https://groupboss.io/groupspro-alternative/"
            >
              The Best Groups Pro Alternative and Review
            </StyledA>{" "}
          </ListText>
        </CustomList>
      </ColoredContainer>
    </GroupManageTitleContainer>

    <TestimonialIndex alternative="groupleads" />
    <CardContainer>
      <CommonCard
        cardTextWeight="500"
        cardText="24/7 Support by real people"
        cardPadding="13px 25px"
      />
      <CommonCard
        cardTextWeight="500"
        cardText="100% Secure payment"
        cardPadding="13px 25px"
      />
      <CommonCard
        cardTextWeight="500"
        cardText="Best customer experience"
        cardPadding="13px 25px"
      />
    </CardContainer>
    <BgImgComp />
  </>
)

export const query = graphql`
  {
    background: file(relativePath: { eq: "background.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    artwork_alternative: file(
      relativePath: { eq: "groupboss-groupx-artwork.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    gb_logo: file(relativePath: { eq: "gb-logo.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    grouptrack_crm_logo: file(
      relativePath: { eq: "group-collector-logo.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    gb_logo_mobile: file(relativePath: { eq: "group-collector-logo.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    gl_logo_mobile: file(
      relativePath: { eq: "group-collector-logo-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    poor_customer: file(
      relativePath: { eq: "feedback-poor-customer-support.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    better_ux: file(
      relativePath: { eq: "feedback-better-user-experience.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    clear_ux: file(relativePath: { eq: "feedback-clean-clear-UX.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    groupboss_poster: file(relativePath: { eq: "shot-1.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    detailed_tutorials: file(
      relativePath: { eq: "feedback-detailed-tutorials.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }

    poor_customer_mobile: file(
      relativePath: { eq: "feedback-poor-customer-support-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    better_ux_mobile: file(
      relativePath: { eq: "feedback-better-user-experience-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    clear_ux_mobile: file(
      relativePath: { eq: "feedback-clean-clear-UX-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    detailed_tutorials_mobile: file(
      relativePath: { eq: "feedback-detailed-tutorials-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }

    tick: file(relativePath: { eq: "tick_2.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 27
          placeholder: NONE
          layout: FIXED
        )
      }
    }
    cross: file(relativePath: { eq: "cross.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 27
          placeholder: NONE
          layout: FIXED
        )
      }
    }
  }
`
